<template>
  <div class="login-container">
    <el-form
      :model="ruleForm"
      :rules="rules"
      status-icon
      ref="ruleForm"
      label-position="left"
      label-width="0px"
      class="demo-ruleForm login-page"
    >
      <h3 class="title">Easy Live</h3>

      <el-form-item prop="username">
        <el-input
          type="text"
          v-model="ruleForm.username"
          auto-complete="off"
          placeholder="賬戶"
        ></el-input>
      </el-form-item>

      <el-form-item prop="password">
        <el-input
          type="password"
          v-model="ruleForm.password"
          auto-complete="off"
          placeholder="密碼"
        ></el-input>
      </el-form-item>

      <el-checkbox v-model="checked" class="rememberme">記住密碼</el-checkbox>

      <el-form-item style="width: 100%">
        <el-button
          type="primary"
          style="width: 100%"
          @click="handleSubmit"
          :loading="logining"
        >
          登入
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import md5 from "blueimp-md5";

export default {
  data() {
    return {
      logining: false,
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "請輸入賬戶",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "請輸入密碼", trigger: "blur" }],
      },
      checked: false,
    };
  },

  methods: {
    handleSubmit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.logining = true;

        let res = await this.$fetch("session", {
          method: "POST",
          body: {
            username: this.ruleForm.username,
            password: md5(this.ruleForm.password),
          },
        });

        this.logining = false;

        if (res.error) {
          this.$message.error(res.message);
          return;
        }

        if (this.checked) {
          localStorage.setItem("token", res.data.token);
        } else {
          sessionStorage.setItem("token", res.data.token);
        }

        this.$router.push({ path: "/" });
      });
    },
  },
};
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100%;
}

.login-page {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 180px auto;
  width: 350px;
  padding: 35px 35px 15px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}
</style>